<template>
  <form-dialog
    v-model="is_open"
    :is-saving="is_saving"
    :title="dialog_title"
    v-on:submit="send"
  >
    <template v-slot:content>
      <v-form ref="form" v-model="form" lazy-validation>
        <v-text-field
          :label="$t('Title')"
          :rules="[(v) => !!v || $t('TitleIsMissing')]"
          v-model="album.title"
          counter
          maxlength="50"
          outlined
          :disabled="is_saving"
          required
        ></v-text-field>
        <v-textarea
          class="mt-3"
          :disabled="is_saving"
          no-resize
          outlined
          rows="3"
          v-model="album.description"
          :placeholder="$t('Optional')"
          :label="$t('Description')"
        ></v-textarea>
      </v-form>
      <error-box :error="error"></error-box>
    </template>
  </form-dialog>
</template>

<i18n>
    {
    "en": {
    "CreateAlbum": "Create album",
    "EditAlbum": "Edit album",
    "Title": "Title",
    "TitleIsMissing": "Title is missing.",
    "Description": "Description",
    "Optional": "Optional",
    "AlbumCreated": "Album created!",
    "AlbumUpdated": "Album updated!"
    },
    "sv": {
    "CreateAlbum": "Skapa album",
    "EditAlbum": "Redigera album",
    "Title": "Titel",
    "TitleIsMissing": "Titel måste anges.",
    "Description": "Beskrivning",
    "Optional": "Frivillig",
    "AlbumCreated": "Albumet skapades!",
    "AlbumUpdated": "Albumet uppdaterades!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "diary_album_form_dialog",
  computed: {
    ...mapState({}),
  },
  components: {},
  data: () => ({
    diary_name: null,
    is_open: false,
    form: false,
    error: null,
    is_saving: false,
    dialog_title: "",

    album: {
      title: "",
      description: "",
      id: null,
    },
  }),
  created: function () {
    this.dialog_title = this.$t("CreateAlbum");
  },
  methods: {
    ...mapActions({
      createInDiary: "diary/albums/createInDiary",
      update: "diary/albums/update",
    }),

    open(diaryName) {
      this.is_saving = false;
      this.error = null;
      this.album.title = "";
      this.album.description = "";
      this.diary_name = diaryName;
      this.is_open = true;
    },
    editAlbum(album) {
      this.is_saving = false;
      this.error = null;
      this.album.title = album.title;
      this.album.description = album.description;
      this.album.id = album.id;
      this.dialog_title = this.$t("EditAlbum");
      this.is_open = true;
      
    },
    send() {
      if (this.album.id) {
        this.updateAlbum();
      } else {
        this.createAlbum();
      }
    },
    createAlbum() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        self
          .createInDiary({ diary: self.diary_name, data: self.album })
          .then(function (response) {
            self.is_saving = false;
            self.is_open = false;
            self.$emit("created", response.data);
            self.$successNoty(self.$t("AlbumCreated"));
            self.$refs.form.resetValidation();
            self.$router.push(
              "/" +
                self.diary_name +
                "/album/" +
                response.data.url_name +
                "/upload"
            );
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
    updateAlbum() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        self
          .update(self.album)
          .then(function () {
            self.is_saving = false;
            self.is_open = false;
            self.$emit("updated", self.album);
            self.$successNoty(self.$t("AlbumUpdated"));
            self.$refs.form.resetValidation();
          })
          .catch(function (error) {
            self.error = error;
            self.is_saving = false;
          });
      }
    },
  },
  watch: {},
};
</script>